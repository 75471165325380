




















import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "afterpay-payment-steps",
  components: {}
})
export default class AfterpayPaymentSteps extends Vue {
  @Prop({ default: null }) readonly orderTotalRemaining!: number;

  firstPrice: string | null = null;
  secondPrice: string | null = null;
  thirdPrice: string | null = null;
  fourthPrice: string | null = null;

  mounted() {
    const singlePrice = (this.orderTotalRemaining / 4).toFixed(2);
    if (singlePrice) {
      this.firstPrice = singlePrice;
      this.secondPrice = singlePrice;
      this.thirdPrice = singlePrice;
      this.fourthPrice = singlePrice;
    }
  }
}
