














































import { Component, Vue } from "vue-property-decorator";

import { AfterpayService } from "../services/afterpay.service";
import { commonErrorMessage } from "../constants/errors";
import AfterpayLogo from "../../common/components/afterpay-logo.vue";
import AfterpayButton from "./afterpay-button.vue";
import AfterpayPaymentSteps from "./afterpay-payment-steps.vue";
import TermsAndConditionsButton from "./terms-and-conditions-button.vue";
import ErrorMessage from "./error-message.vue";

@Component({
  name: "payment-view",
  components: {
    AfterpayLogo,
    SuccessfulOverlay: () =>
      import(
        /* webpackChunkName: "successful-overlay" */ "./successful-overlay.vue"
      ),
    AfterpayPaymentSteps,
    TermsAndConditionsButton,
    ErrorMessage,
    AfterpayButton
  }
})
export default class PaymentView extends Vue {
  $afterpayPopupWindow: any;
  $afterpayWidget!: HTMLElement;
  $payButton!: HTMLElement;
  $checkbox!: HTMLFormElement;
  afterpayConfiguration = null;
  showSuccessfulOverlay = false;
  showPayButtonLoader = false;
  showConfigurationError = false;
  afterpayScriptLoaded = false;
  showPaymentError = false;
  errorMessage = "";
  defaultErrorMessage = commonErrorMessage;
  errors = {
    minimumAmount:
      "Payment Failed: Your Purchase must be at least [AMOUNT] [CURRENCY] to pay with Afterpay.",
    maximumAmount:
      "Payment Failed: Your Purchase must be less than [AMOUNT] [CURRENCY] to pay with Afterpay."
  };
  widgetHeights = {
    COLLAPSED: 55
  };
  shop: string | null = null;
  platform: string | null = null;
  sandbox: string | null = null;
  orderTotalRemaining: number | null = null;

  async initWidget() {
    this.setIframeHeight(this.widgetHeights.COLLAPSED);
    try {
      this.afterpayConfiguration = await this.getAfterpayConfiguration();
      this.initializeErrorMessages(this.afterpayConfiguration);
    } catch (error) {
      this.showConfigurationError = true;
    }

    this.$payButton?.addEventListener("click", async () => {
      try {
        const AfterPay: any = (window as any | Window).AfterPay;
        this.addLoaderToPayButton();
        this.disableClickOnPayButton();
        AfterPay.initialize({ countryCode: "US" });
        this.$afterpayPopupWindow = AfterPay.open();
        await this.payWithAfterpay();
        if (!this.showSuccessfulOverlay) {
          this.showDoneOverlay();
          this.showPaymentError = false;
        } else {
          this.hideDoneOverlay();
        }
        this.startAttemptToSubmitOrder();
      } catch (error) {
        if (!this.$afterpayPopupWindow.closed) {
          this.$afterpayPopupWindow.close();
        }
        this.removeLoaderToPayButton();
        this.showErrorMessage(error);
      } finally {
        this.enableClickOnPayButton();
      }
    });

    this.$checkbox.addEventListener("change", () => {
      if (this.$checkbox.checked) {
        this.expandWidget();
      } else {
        this.collapseWidget();
      }
    });

    window.addEventListener("resize", this.handleResize);
  }
  expandWidget() {
    this.$afterpayWidget.classList.add("afterpay--expanded");
    this.setIframeHeight(document.documentElement.offsetHeight);
  }
  collapseWidget() {
    this.$afterpayWidget.classList.remove("afterpay--expanded");
    this.setIframeHeight(this.widgetHeights.COLLAPSED);
  }

  initializeErrorMessages(afterpayConfiguration: any) {
    this.errors.minimumAmount = this.errors.minimumAmount.replace(
      "[AMOUNT]",
      afterpayConfiguration.minimumAmount.amount
    );
    this.errors.minimumAmount = this.errors.minimumAmount.replace(
      "[CURRENCY]",
      afterpayConfiguration.minimumAmount.currency
    );
    this.errors.maximumAmount = this.errors.maximumAmount.replace(
      "[AMOUNT]",
      afterpayConfiguration.maximumAmount.amount
    );
    this.errors.maximumAmount = this.errors.maximumAmount.replace(
      "[CURRENCY]",
      afterpayConfiguration.maximumAmount.currency
    );
  }
  async payWithAfterpay() {
    const order = (await this.requestOrderToBold()) as any;
    const afterpayPaymentValidation = this.checkIfOrderIsAfterpayIsValid(
      order,
      this.afterpayConfiguration
    );
    if (!afterpayPaymentValidation.isValid) {
      throw new Error(afterpayPaymentValidation.errorMessage);
    } else {
      this.hideErrorMessage();
      await this.startPayment(order);
    }
  }
  requestOrderToBold() {
    return new Promise(resolve => {
      function receiveOrderListener(event: any) {
        const message = event.data;
        switch (message.type) {
          case "checkout/receive_order":
            resolve(message.payload);
            window.removeEventListener("message", receiveOrderListener);
            break;
        }
      }
      window.addEventListener("message", receiveOrderListener);
      parent.postMessage(
        {
          type: "checkout/request_order"
        },
        "*"
      );
    });
  }
  checkIfOrderIsAfterpayIsValid(
    order: any,
    configuration: any
  ): { isValid: boolean; errorMessage: string } {
    return AfterpayService.checkIfOrderIsValid(
      order,
      configuration,
      this.errors
    );
  }

  async startPayment(order: any) {
    const afterpayToken = await this.getAfterpayToken(order);
    await this.startAfterpayPopupFlow(afterpayToken.token, order);
  }

  getAfterpayToken(order: any) {
    if (this.shop && this.platform) {
      return AfterpayService.getCheckoutToken(order, this.shop, this.platform);
    } else {
      throw new Error("No shop detected.");
    }
  }
  getAfterpayConfiguration() {
    if (this.shop && this.platform) {
      return AfterpayService.getConfiguration(this.shop, this.platform);
    } else {
      throw new Error("No shop detected.");
    }
  }
  addAfterpayPayment(orderToken: string, order: any) {
    parent.postMessage(
      {
        type: "checkout/app_hook",
        payload: {
          hook: "app_hook",
          data: { orderRemaining: order.total_remaining, token: orderToken }
        }
      },
      "*"
    );
  }
  autoSubmitOrder() {
    parent.postMessage(
      {
        type: "checkout/process_order"
      },
      "*"
    );
  }
  setIframeHeight(height: number) {
    parent.postMessage(
      {
        type: "checkout/resize_frame",
        payload: { height: height }
      },
      "*"
    );
  }
  startAfterpayPopupFlow(incomingToken: string, order: any) {
    return new Promise((resolve, reject) => {
      const AfterPay: any = (window as any | Window).AfterPay;
      // If you don't already have a checkout token at this point, you can
      // AJAX to your backend to retrieve one here. The spinning animation
      // will continue until `AfterPay.transfer` is called.
      AfterPay.onComplete = (event: any) => {
        if (event.data.status == "SUCCESS") {
          // The consumer confirmed the payment schedule.
          // The token is now ready to be captured from your server backend.
          const orderToken = event.data.orderToken;
          this.addAfterpayPayment(orderToken, order);
          resolve(order);
        } else {
          reject(
            "The consumer cancelled the payment or closed the popup window."
          );
        }
      };
      AfterPay.transfer({ token: incomingToken });
    });
  }

  startAttemptToSubmitOrder() {
    const timeout = 10 * 1000; // 10 seconds
    let timeoutCounter = 0;
    const intervalId = setInterval(async () => {
      timeoutCounter += 1000;
      if (timeoutCounter < timeout) {
        const order = (await this.requestOrderToBold()) as any;
        if (order.total_remaining === 0) {
          this.autoSubmitOrder();
          clearInterval(intervalId);
        }
      } else {
        this.hideDoneOverlay();
        clearInterval(intervalId);
      }
    }, 1000);
  }

  addLoaderToPayButton() {
    this.showPayButtonLoader = true;
  }
  removeLoaderToPayButton() {
    this.showPayButtonLoader = false;
  }
  disableClickOnPayButton() {
    this.$payButton.setAttribute("disabled", "true");
  }
  enableClickOnPayButton() {
    this.$payButton.removeAttribute("disabled");
  }
  showErrorMessage(message: string) {
    this.errorMessage = message;
    this.showPaymentError = true;
    this.$nextTick(() => {
      this.setIframeHeight(document.documentElement.offsetHeight);
    });
  }
  hideErrorMessage() {
    this.showPaymentError = false;
    this.$nextTick(() => {
      this.setIframeHeight(document.documentElement.offsetHeight);
    });
  }
  showDoneOverlay() {
    this.showSuccessfulOverlay = true;
  }
  hideDoneOverlay() {
    this.showSuccessfulOverlay = false;
  }

  hideInitialLoader() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("initial-loader")!.style.display = "none";
  }

  get documentHeight() {
    return document.documentElement.offsetHeight;
  }

  handleResize() {
    const targetHeight = this.$afterpayWidget.classList.contains(
      "afterpay--expanded"
    )
      ? document.documentElement.offsetHeight
      : this.widgetHeights.COLLAPSED;
    this.setIframeHeight(targetHeight);
  }

  async getOrderTotalRemaining() {
    const order: any = await this.requestOrderToBold();
    this.orderTotalRemaining = order.total_remaining / 100;
  }

  async mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.shop = urlParams.get("shop");
    this.platform = urlParams.get("platform");
    this.sandbox = urlParams.get("sandbox");
    this.$afterpayWidget = document.getElementById(
      "afterpay-widget"
    ) as HTMLElement;
    this.$payButton = document.getElementById(
      "afterpay-pay-button"
    ) as HTMLElement;
    this.$checkbox = document.getElementById(
      "afterpay-checkbox"
    ) as HTMLFormElement;
    AfterpayService.loadAfterpayJavascriptFile(async () => {
      this.afterpayScriptLoaded = true;
      await this.initWidget();
      await this.getOrderTotalRemaining();
      this.hideInitialLoader();
    }, this.sandbox);
  }
}
