import { commonErrorMessage } from "../constants/errors";
import { BoldService } from "./bold.service";

export const AfterpayService = {
  checkIfOrderIsValid(
    order: any,
    configuration: any,
    errors: any
  ): { isValid: boolean; errorMessage: string } {
    let isValid = false;
    let errorMessage = "";
    const hasValidCurrency =
      order.currency === configuration.minimumAmount.currency &&
      order.currency === configuration.maximumAmount.currency;
    const amountHigherThanMinimum =
      order.total_remaining >=
      BoldService.formatToBoldAmount(configuration.minimumAmount.amount);
    const amountBelowMaximum =
      order.total_remaining <=
      BoldService.formatToBoldAmount(configuration.maximumAmount.amount);
    const hasValidAmount = amountHigherThanMinimum && amountBelowMaximum;
    if (!amountHigherThanMinimum) {
      errorMessage = errors.minimumAmount;
    }
    if (!amountBelowMaximum) {
      errorMessage = errors.maximumAmount;
    }

    // TODO(zarco): Add error messages for both cases.
    if (hasValidAmount && hasValidCurrency) {
      isValid = true;
    }
    return { isValid, errorMessage };
  },
  getCheckoutToken(order: any, shop: string, platform: string) {
    const url = `${process.env.VUE_APP_API_PROTOCOL}${process.env.VUE_APP_API_DOMAIN}/payment/checkout?platform=${platform}&shop=${shop}`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify({ order: order }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => {
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Oops, we haven't got JSON!");
      }

      if (response.status !== 201) {
        throw new Error(commonErrorMessage);
      }
      return response.json();
    });
  },
  getConfiguration(shop: string, platform: string) {
    const url = `${process.env.VUE_APP_API_PROTOCOL}${process.env.VUE_APP_API_DOMAIN}/payment/configuration?platform=${platform}&shop=${shop}`;
    return fetch(url, {
      method: "GET"
    }).then(response => {
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new TypeError("Oops, we haven't got JSON!");
      }
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
  },
  async loadAfterpayJavascriptFile(
    onScriptLoaded: () => Promise<void>,
    sandbox: string | null
  ) {
    const isSandbox = Boolean(sandbox);
    const plugin = document.createElement("script");
    let afterpayJSUrl = "https://portal.afterpay.com/afterpay.js";
    if (isSandbox) {
      afterpayJSUrl = "https://portal.sandbox.afterpay.com/afterpay.js";
    }
    plugin.setAttribute("src", afterpayJSUrl);
    // plugin.async = true;
    plugin.onload = async () => {
      await onScriptLoaded();
    };
    document.head.appendChild(plugin);
  }
};
